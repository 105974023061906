export const downloadFile = (file, fileName) => {
  let tag = document.createElement('a');
  tag.className = 'download-file';
  tag.style = 'display: none';
  tag.href = file;
  tag.download = fileName;

  document.body.appendChild(tag);
  tag.click();
  document.getElementsByClassName('download-file')[0].remove();
};
