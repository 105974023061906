<template>
  <v-container>
    <v-form
      @submit.prevent="changeDocument"
      ref="form"
      lazy-validation
      v-model="valid"
      v-if="canEdit"
    >
      <v-row dense>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <v-file-input
            label="Arquivo"
            dense
            outlined
            required
            accept=".pdf,.doc,.docx,.xlsx,.xls"
            :rules="[required]"
            v-model="document.file"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <BaseDocumentType
            label="Tipo"
            required
            :rules="[required]"
            v-model="document.documentType"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="6">
          <v-autocomplete
            label="Titular ou dependente"
            item-text="description"
            item-value="id"
            dense
            outlined
            required
            :rules="[required]"
            :items="employees"
            v-model="document.employeeOrDependentId"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <BaseButton
            type="submit"
            color="primary"
            :disabled="!valid"
            title="Adicionar"
            height="40"
          />
        </v-col>
      </v-row>
    </v-form>

    <BaseTableList dense title="Listagem de documentos">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="download(item)"
                >
                  fa-solid fa-download
                </v-icon>
              </template>
              <span>Baixar arquivo</span>
            </v-tooltip>

            <v-tooltip bottom v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="deleteDocument(item)"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  props: {
    customerId: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    document: {
      file: null,
      employeeOrDependentId: null,
      documentType: null
    },
    options: {},
    headers: [
      { text: 'Documento', value: 'documentType' },
      { text: 'Data de envio', value: 'createdAt' },
      { text: 'Vida', value: 'lifeName' },
      { text: '', value: 'action' }
    ],
    items: [],
    totalRecords: 0,
    employees: []
  }),

  computed: {
    ...mapGetters({
      employee: 'movements/getEmployee'
    }),

    canEdit() {
      const situation =
        this.employee.situation === 'Ativo' ||
        this.employee.situation === 'Em análise na operadora' ||
        this.employee.situation === null;

      return situation;
    }
  },

  created() {
    this.getEmployees();
  },

  methods: {
    formatDate,

    back() {
      this.$emit('back');
    },

    async getEmployees() {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getAvailableMovementDocumentLives({
            customerId: this.customerId,
            employeeId: this.employeeId
          });

        if (status === 200) {
          this.employees = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const movementService = new MovementService();
        const { status, data } =
          await movementService.getPagedEmployeeDocuments({
            page,
            take: itemsPerPage,
            employeeId: this.employeeId
          });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async changeDocument() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const params = Object.assign({}, this.document);
        params.employeeId = this.employeeId;

        const movementService = new MovementService();
        const { status } = await movementService.uploadEmployeeDocument(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async deleteDocument(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const params = Object.assign({}, item);
          params.employeeId = this.employeeId;

          const movementService = new MovementService();
          const { status } = await movementService.deleteDocument(params);

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    async download(item) {
      try {
        const params = Object.assign({}, item);
        params.employeeId = this.employeeId;

        const movementService = new MovementService();
        const { status, data } = await movementService.downloadDocument(params);

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
